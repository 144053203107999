import { KEY_RETURN } from "keycode-js";
import { useEffect, useState } from "react";
import Filter from "../../models/filter";
import MilepostManager from "../../models/milepostManager";
import Navigable, { NavigatorMove, SetFilters } from "../../models/navigable";
import Navigator from "../../models/navigator";
import NavigatorPosition from "../../models/navigatorPosition";
import ProjectInfo from "../../models/projectInfo";
import LogDirectory from "../../services/logDirectory";
import LogService from "../../services/logService";
import numberHelper from "../../util/numberHelper";
import Dropdown from "../common/Dropdown";
import DropdownOption from "../common/DropdownOption";

const logService = new LogService(LogDirectory.EnableMilepostLogging);

const Milepost: React.FC<{ navigator: Navigator; project: ProjectInfo }> = ({
  navigator,
  project,
}) => {
  const [milepostManager, setMilepostManager] = useState<MilepostManager>();
  const [mileposts, setMileposts] = useState<DropdownOption<string>[]>([]);
  const [milepost, setMilepost] = useState<DropdownOption<string>>();

  const [offset, setOffset] = useState<number | undefined>();

  useEffect(() => {
    logService.log("milepost:init()");

    const navigable = new Navigable();
    navigable.id = "milepost";

    const milepostManager = new MilepostManager(navigator, project);
    setMilepostManager(milepostManager);

    milepostManager.onMilepostsChanged = (mps: string[]) => {
      const dropdownOptions: DropdownOption<string>[] = [];
      for (const mp of mps) {
        const newOption = new DropdownOption<string>();
        newOption.value = mp;
        newOption.label = mp;
        dropdownOptions.push(newOption);
      }
      setMileposts(dropdownOptions);
    };

    milepostManager.onMilepostChanged = (mp: string | null) => {
      if (mp === null) {
        setMilepost(undefined);
        return;
      }

      const newOption = new DropdownOption<string>();
      newOption.value = mp;
      newOption.label = mp;
      setMilepost(newOption);
    };

    milepostManager.onOffsetChanged = (offset: number | undefined) => {
      setDisplayOffset(offset);
    };

    const navigatorMove: NavigatorMove = async (
      navigatorPosition: NavigatorPosition
    ) => {
      logService.log("milepost:navigator:move()", navigatorPosition);
      milepostManager.move(navigatorPosition);
    };
    navigable.navigatorMove = navigatorMove;

    const navigatorFiltersChanged: SetFilters = async (filters: Filter[]) => {
      logService.log("milepost:navigator:setFilters()", filters);
      milepostManager.setFilters(filters);
    };
    navigable.setFilters = navigatorFiltersChanged;

    navigator.attach(navigable);

    return () => navigator.detach(navigable);
  }, [navigator, project]);

  const setDisplayOffset = (milepostValue: number | undefined) => {
    const value = numberHelper.numberTo4Digits(milepostValue);
    setOffset(value);
  };

  const onOffsetChanged = (event: any) => {
    const milepostValue = Number(event.target.value);
    setDisplayOffset(milepostValue);
  };

  const moveToOffset = async (offset: number) => {
    await milepostManager?.moveToOffset(offset);
  };

  const onOffsetLostFocus = (event: any) => {
    logService.log("milepost:onLostFocus()");
    if (offset !== undefined) {
      moveToOffset(offset);
    }
  };

  const onOffsetKeyDown = (event: any) => {
    if (event.which === KEY_RETURN) {
      logService.log("milepost:onEnterKeyDown()");
      event.preventDefault();
      if (offset !== undefined) {
        moveToOffset(offset);
      }
    }
  };

  const onMilepostChange = async (newValue: DropdownOption<string>) => {
    if (newValue.value) {
      await milepostManager?.moveToMilepost(newValue.value);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div className="menu-dropdown menu-item-spacing-right">
        <div className="locators-title">Milepost</div>
        <Dropdown
          options={mileposts}
          value={milepost}
          onChange={(newValue: any) => onMilepostChange(newValue)}
          disabled={offset === undefined}
        />
      </div>
      <div style={{ fontSize: "12px" }} className="menu-item-spacing-right">
        <div className="locators-title">Offset</div>
        <input
          // className="form-control btn-secondary"
          className="form-control"
          style={{
            width: "66px",
            padding: "5px",
            fontWeight: "bold",
            // backgroundColor: "white",
            // color: "black",
          }}
          type="number"
          value={offset ?? ""}
          onChange={onOffsetChanged}
          step="0.001"
          onBlur={onOffsetLostFocus}
          onKeyDown={onOffsetKeyDown}
          disabled={offset === undefined}
        />
      </div>
    </div>
  );
};

export default Milepost;
