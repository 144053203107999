const hexArgbToRgbAHex = (hexArgb: string) => {
  if (hexArgb.at(0) !== "#") {
    return hexArgb;
  }

  const a = parseInt(hexArgb.slice(1, 3), 16);
  const r = parseInt(hexArgb.slice(3, 5), 16);
  const g = parseInt(hexArgb.slice(5, 7), 16);
  const b = parseInt(hexArgb.slice(7, 9), 16);

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

const presetColors = [
  "#D0021B",
  "#F5A623",
  "#F8E71C",
  "#8B572A",
  "#7ED321",
  "#417505",
  "#BD10E0",
  "#9013FE",
  "#4A90E2",
  "#50E3C2",
  "#B8E986",
  "#000000",
  "#4A4A4A",
  "#9B9B9B",
  "#FFFFFF",
];

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const colorHelper = {
  presetColors,
  getRandomColor,
  hexArgbToRgbAHex,
};

export default colorHelper;
