import { useEffect, useState } from "react";
import Select from "react-select";

const Dropdown: React.FC<{
  options: any;
  value: any;
  onChange: any;
  disabled?: any;
  minWidth?: string;
  height?: string;
}> = ({
  options,
  value,
  onChange,
  disabled = undefined,
  minWidth = "46px",
  height = "28px",
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const fontSize: string = "12px";

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: height,
      height: height,
      minWidth: minWidth,
      boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: height,
      fontSize: fontSize,
      padding: "0 6px",
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: height,
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      fontSize: fontSize,
      width: "max-content",
      minWidth: "100%",
    }),
  };

  const onExpanderClick = (e: any) => {
    setMenuIsOpen((prev) => !prev);
  };

  const onBlur = (e: any) => {
    setMenuIsOpen(false);
  };

  const onFocus = (e: any) => {
    setMenuIsOpen(true);
  };

  const onChangeInternal = (newValue: any) => {
    setMenuIsOpen(false);
    onChange(newValue);
  };

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "react-select__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      }
    }, 15);
  };

  useEffect(() => {
    if (menuIsOpen) {
      onMenuOpen();
    }
  }, [menuIsOpen]);

  return (
    <div className="input-group input-group-sm" style={{ width: "auto" }}>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={options}
        styles={customStyles}
        value={value}
        onChange={(newValue) => onChangeInternal(newValue)}
        placeholder=""
        menuIsOpen={menuIsOpen}
        onBlur={onBlur}
        onFocus={onFocus}
        //onMenuOpen={onMenuOpen}
        isDisabled={disabled}
      />
      <button
        type="button"
        className="btn btn-primary dropdown-toggle dropdown-toggle-split"
        aria-expanded="false"
        style={{ marginLeft: "0px", height: height }}
        onClick={onExpanderClick}
        onBlur={onBlur}
        disabled={disabled}
      >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
    </div>
  );
};

export default Dropdown;
