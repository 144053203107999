import { useEffect, useState } from "react";
import MapLayer from "../../../models/mapLayer";
import ThematicMapLayerRange from "../../../models/thematicMapLayerRange";

const ThematicMapLayerLegend: React.FC<{ mapLayer: MapLayer }> = ({
  mapLayer,
}) => {
  const [ranges, setRanges] = useState<ThematicMapLayerRange[]>([]);

  useEffect(() => {
    if (!mapLayer.thematicMapLayer) {
      return;
    }

    const newRanges = [...mapLayer.thematicMapLayer.thematicMapLayerRanges];

    newRanges.sort((a: ThematicMapLayerRange, b: ThematicMapLayerRange) => {
      const isAsc = true;

      if (isAsc) {
        if (!a.hasRangeStart && b.hasRangeStart) {
          return -1;
        }

        if (a.hasRangeStart && !b.hasRangeStart) {
          return 1;
        }

        if (a.rangeStartValue < b.rangeStartValue) {
          return -1;
        }

        if (a.rangeStartValue > b.rangeStartValue) {
          return 1;
        }
      } else {
        if (!a.hasRangeStart && b.hasRangeStart) {
          return 1;
        }

        if (a.hasRangeStart && !b.hasRangeStart) {
          return -1;
        }

        if (a.rangeStartValue < b.rangeStartValue) {
          return 1;
        }

        if (a.rangeStartValue > b.rangeStartValue) {
          return -1;
        }
      }

      return 0;
    });

    setRanges(newRanges);
  }, [mapLayer]);

  return (
    <div
      style={{
        fontSize: "10px",
        fontWeight: "bold",
      }}
    >
      <div className="d-flex">
        <div
          className="align-self-end"
          style={{ marginBottom: "-4px", marginRight: "4px" }}
        >
          {mapLayer.layerName}
        </div>
        {ranges.map((range, index) => (
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ position: "relative" }}
            key={index.toString()}
          >
            <div
              style={{
                position: "absolute",
                left: "-4px",
                top: "0px",
              }}
            >
              {range.rangeStartDisplayValue}
            </div>
            <div>
              <div
                style={{
                  padding: "0px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {range.rangeLabel === "" && <span>&nbsp;</span>}
                {range.rangeLabel !== "" && <span>{range.rangeLabel}</span>}
              </div>
              {index === ranges.length - 1 && (
                <div
                  style={{
                    position: "absolute",
                    right: "-4px",
                    top: "0px",
                  }}
                >
                  {range.rangeEndDisplayValue}
                </div>
              )}
            </div>
            <div
              style={{
                height: "10px",
                minWidth: "80px",
                width: "100%",
                background: range.rangeColor,
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThematicMapLayerLegend;
