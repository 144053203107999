export default class Project {
  public idProject: number = 0;
  public projectName: string = "";
  public projectCode: string = "";
  public year: string = "";
  public databaseName: string = "";
  public reportName: string = "";
  public defaultWorkspaceLayout: string = "";
  public defaultLocation: string = "";
  public hasMilepostNavigation: boolean = false;
  public hasAllRoadsMBTiles: boolean = false;
  public chainageDisplayName: string = "";
  public chainageDisplayNameShort: string = "";
  public showChainageDefault: boolean = true;
  public showUnitsInTableColumnHeaders: boolean = false;
  public isOnline: boolean = true;
}
