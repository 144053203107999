export default class ProjectInfo {
  public idProject: number = 0;
  public projectName: string = "";
  public projectCode: string = "";
  public year: string = "";
  public hasMilepostNavigation: boolean = false;
  public hasAllRoadsMBTiles: boolean = false;
  public hasThematicMaps: boolean = false;
  public chainageDisplayName: string = "";
  public chainageDisplayNameShort: string = "";
  public showChainageDefault: boolean = true;
  public showUnitsInTableColumnHeaders: boolean = false;
  public hasUturn: boolean = true;
  public hasDistress: boolean = false;
}
