import { useContext, useEffect, useState } from "react";
import Dropdown from "../common/Dropdown";
import DropdownOption from "../common/DropdownOption";
import Organization from "../../models/organization";
import OrganizationContextModel from "../../contexts/organizationContextModel";
import OrganizationContext from "../../contexts/organizationContext";

const CurrentOrganization: React.FC = () => {
  const organizationContext = useContext<OrganizationContextModel | undefined>(
    OrganizationContext
  );

  const [organizationsOptions, setOrganizationsOptions] = useState<
    DropdownOption<number>[]
  >([]);

  const [currentOrganizationOption, setCurrentOrganizationOption] =
    useState<DropdownOption<number>>();

  useEffect(() => {
    if (!organizationContext) {
      return;
    }

    const dropdownOptions: DropdownOption<number>[] = [];
    for (const organization of organizationContext.organizations) {
      dropdownOptions.push(organizationToDropdownOption(organization));
    }
    setOrganizationsOptions(dropdownOptions);

    const currentOrganizationOption = dropdownOptions.find(
      (opt) =>
        opt.value === organizationContext.currentOrganization?.idOrganization
    );
    setCurrentOrganizationOption(currentOrganizationOption);

    const onOrganizationsChanged = () => {
      const dropdownOptions: DropdownOption<number>[] = [];
      for (const organization of organizationContext.organizations) {
        dropdownOptions.push(organizationToDropdownOption(organization));
      }
      setOrganizationsOptions(dropdownOptions);
    };

    organizationContext.onOrganizationsChanged = onOrganizationsChanged;

    return () => {
      organizationContext.onOrganizationsChanged = undefined;
    };
  }, [organizationContext, organizationContext?.organizations]);

  const onOrganizationChanged = (newValue: DropdownOption<number>) => {
    setCurrentOrganizationOption(newValue);

    if (!organizationContext) {
      return;
    }

    const currentOrganization = organizationContext.organizations.find(
      (o) => o.idOrganization === Number(newValue.value)
    );
    if (currentOrganization) {
      organizationContext.currentOrganization = currentOrganization;
      organizationContext.invokeCurrentOrganizationChanged();
    }
  };

  const organizationToDropdownOption = (organization: Organization) => {
    const dropdownOption = new DropdownOption<number>();
    dropdownOption.label = organization.organizationName;
    dropdownOption.value = organization.idOrganization;
    return dropdownOption;
  };

  return (
    <Dropdown
      options={organizationsOptions}
      value={currentOrganizationOption}
      onChange={(newValue: any) => onOrganizationChanged(newValue)}
    />
  );
};

export default CurrentOrganization;
