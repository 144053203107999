import { Tooltip } from "bootstrap";
import { useEffect, useState, useRef } from "react";
import ImageDownload from "../../models/imageDownload";
import JobStatus from "../../models/jobStatus";
import ProjectInfo from "../../models/projectInfo";
import imageService from "../../services/imageService";
import dateTimeHelper from "../../util/dateTimeHelper";
import formatBytes from "../../util/formatBytes";
import promiseHelper from "../../util/promiseHelper";
import Job from "../../models/job";

const ImageDownloads: React.FC<{
  project: ProjectInfo;
  onNewImageDownload: any;
  onViewImageDownload: any;
  onCancelImageDownload: any;
  onRemoveImageDownload: any;
  onHasRunningTasks: any;
  refresh: Boolean;
}> = ({
  project,
  onNewImageDownload,
  onViewImageDownload,
  onCancelImageDownload,
  onRemoveImageDownload,
  onHasRunningTasks,
  refresh,
}) => {
  const downloadLimit = 3;
  const downloadLimitMessage =
    "Download limit reached. Remove existing downloads to do more downloads";

  const [imageDownloads, setImageDownloads] = useState<ImageDownload[]>([]);
  const [downloadLimitReached, setDownloadLimitReached] =
    useState<boolean>(false);

  const [refreshCount, setRefreshCount] = useState<number>(0);

  const tooltipsRefs = useRef<HTMLElement[]>([]);
  const [tooltips, setTooltips] = useState<Tooltip[]>([]);

  const closeTooltips = () => {
    // console.log("closeTooltips", tooltips);
    for (const tooltip of tooltips) {
      tooltip.hide();
    }

    // console.log("closeTooltipsRefs", tooltipsRefs.current);
    for (const elref of tooltipsRefs.current) {
      const tooltipId = elref.getAttribute("aria-describedby");
      if (tooltipId) {
        document.getElementById(tooltipId)?.remove();
      }
    }
  };

  useEffect(() => {
    if (refresh.valueOf() === true) {
      setRefreshCount((prev) => prev + 1);
    }
  }, [refresh]);

  useEffect(() => {
    let hasRunningTasks = true;

    const init = async () => {
      while (hasRunningTasks) {
        // console.log("Refreshing downloads list");
        const imageDownloads = await imageService.getImageDownloads(
          project.idProject
        );

        if (imageDownloads.length >= downloadLimit) {
          setDownloadLimitReached(true);
        } else {
          setDownloadLimitReached(false);
        }

        if (!hasRunningTasks) {
          onHasRunningTasks(false);
          return;
        }

        setImageDownloads(imageDownloads);

        hasRunningTasks =
          imageDownloads.find(
            (id) =>
              id.job.jobStatus === JobStatus.Scheduled ||
              id.job.jobStatus === JobStatus.InProgress
          ) !== undefined;

        if (hasRunningTasks) {
          onHasRunningTasks(true);
          await promiseHelper.delay(1000);
        } else {
          onHasRunningTasks(false);
        }
      }
    };

    init();

    return () => {
      hasRunningTasks = false;
    };
  }, [project.idProject, refreshCount, onHasRunningTasks]);

  const onNewImageDownloadClick = () => {
    closeTooltips();
    onNewImageDownload();
  };

  const onRemoveImageDownloadClick = async (imageDownload: ImageDownload) => {
    closeTooltips();
    await onRemoveImageDownload(imageDownload);
    setRefreshCount((prev) => prev + 1);
  };

  const onViewImageDownloadClick = (e: any, imageDownload: ImageDownload) => {
    closeTooltips();
    onViewImageDownload(imageDownload);
  };

  const onCancelImageDownloadClick = (imageDownload: ImageDownload) => {
    closeTooltips();
    onCancelImageDownload(imageDownload);
  };

  let tooltipId = 1;

  const createTooltip = (ref: HTMLElement | null) => {
    if (ref) {
      // console.log("createTooltipCalled");
      let alredyAdded = false;
      for (let existingRef of tooltipsRefs.current) {
        if (
          ref.getAttribute("data-key") === existingRef.getAttribute("data-key")
        ) {
          alredyAdded = true;
          break;
        }
      }
      if (!alredyAdded) {
        // console.log("createNewTooltipNew", ref as HTMLElement);
        const tooltip = new Tooltip(ref);

        tooltips.push(tooltip);
        const tooltipsArray = [...tooltips];
        setTooltips(tooltipsArray);

        tooltipsRefs.current.push(ref);
      }
    }
  };

  const getExpires = (expiryDateUtc: Date | undefined) => {
    if (!expiryDateUtc) {
      return undefined;
    }

    const expiryDuration = dateTimeHelper.dateDiffNowUtc(expiryDateUtc);
    return expiryDuration?.indexOf("-") !== -1 ? "Expired" : expiryDuration;
  };

  const getJobStatusText = (job: Job) => {
    if (job.jobStatus === JobStatus.InProgress) {
      return "In Progress";
    }

    if (job.jobStatus === JobStatus.CompletedWithWarnings) {
      return "Completed with Warnings";
    }

    return job.jobStatus;
  };

  const getJobStatusTooltip = (job: Job) => {
    if (job.jobStatus === JobStatus.Error) {
      return `Info - IDJob ${job.idJob} - Error: ${job.statusMessage}`;
    }

    if (job.jobStatus === JobStatus.CompletedWithWarnings) {
      return `Info - IDJob ${job.idJob} - Completed with Warnings: ${job.statusMessage}`;
    }

    return "Info";
  };

  const getJobStatusColor = (job: Job) => {
    if (job.jobStatus === JobStatus.Error) {
      return "red";
    }

    if (job.jobStatus === JobStatus.CompletedWithWarnings) {
      return "orange";
    }

    return "initial";
  };

  return (
    <div>
      <h6 style={{ fontWeight: "400" }} className="mb-2">
        Image Downloads
      </h6>
      <table className="table table-hover">
        <thead>
          <tr>
            {/* <th scope="col">ID</th> */}
            <th scope="col">Download Name</th>
            <th scope="col">Status</th>
            <th scope="col">Image Archive Link</th>
            <th scope="col">Image Archive Size</th>
            <th scope="col">Expires</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {imageDownloads.map((imageDownload: ImageDownload, index) => (
            <tr key={index.toString()}>
              <td>{imageDownload.job.jobName}</td>
              <td>{getJobStatusText(imageDownload.job)}</td>
              <td>
                {imageDownload.hasImageArchiveLink() && (
                  <a
                    href={imageDownload.imageArchiveUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                )}
              </td>
              <td>
                {imageDownload.hasImageArchiveLink() &&
                  formatBytes(imageDownload.imageArchiveSize, 2)}
              </td>
              <td
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-key={`tooltip__${tooltipId++}`}
                ref={(ref) => createTooltip(ref)}
              >
                {getExpires(imageDownload.job.expiryDateUtc)}
              </td>
              <td>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "60px" }}
                >
                  <i
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={getJobStatusTooltip(imageDownload.job)}
                    className="navbar-nav-fa fa fa-info-circle fa-lg"
                    style={{
                      color: getJobStatusColor(imageDownload.job),
                    }}
                    onClick={(e) => onViewImageDownloadClick(e, imageDownload)}
                    data-key={`tooltip__${tooltipId++}`}
                    ref={(ref) => createTooltip(ref)}
                  ></i>
                  <i
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Cancel"
                    className="navbar-nav-fa fa fa-ban fa-lg"
                    onClick={(e) => onCancelImageDownloadClick(imageDownload)}
                    data-key={`tooltip__${tooltipId++}`}
                    ref={(ref) => createTooltip(ref)}
                  ></i>
                  <i
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Remove"
                    className="navbar-nav-fa fa fa-times-circle fa-lg"
                    onClick={(e) => onRemoveImageDownloadClick(imageDownload)}
                    data-key={`tooltip__${tooltipId++}`}
                    ref={(ref) => createTooltip(ref)}
                  ></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={(e) => onNewImageDownloadClick()}
        disabled={downloadLimitReached}
      >
        New Download
      </button>
      {downloadLimitReached && (
        <span style={{ fontWeight: "bold" }} className="ms-2 text-secondary">
          {downloadLimitMessage}
        </span>
      )}
    </div>
  );
};

export default ImageDownloads;
