import { useCallback, useContext, useEffect, useState } from "react";
import formHelper from "../../../util/formHelper";
import Dropdown from "../../common/Dropdown";
import ProjectInfo from "../../../models/projectInfo";
import TableColum from "../../../models/tableColumn";
import DropdownOption from "../../common/DropdownOption";
import ThematicRangesEditor from "./ThematicRangesEditor";
import ThematicValuesEditor from "./ThematicValuesEditor";
import unitConverter from "../../../util/units/unitConverter";
import ThematicMapLayerRange from "../../../models/thematicMapLayerRange";
import TableColumnDataType from "../../../models/tableColumnDataType";
import MapLayer from "../../../models/mapLayer";
import ModalWindow from "../../common/overlays/ModalWindow";
import ThematicMapLayerRangeType from "../../../models/thematicMapLayerRangeType";
import ValidatorManager from "../../../components/common/validationManager";
import Validator from "../../common/Validator";
import User from "../../../models/user";
import UserContext from "../../../contexts/userContext";
import LockIcon from "@mui/icons-material/Lock";
import { Tooltip } from "@mui/material";

const validator = {
  layerName: "layerName",
};

const ThematicMapLayerEdit: React.FC<{
  project: ProjectInfo;
  tableColumns: TableColum[];
  mapLayer: MapLayer;
  isNew: boolean;
  onCreateMapLayer: any;
  onUpdateMapLayer: any;
  onDeleteMapLayer: any;
  onCancel: any;
}> = ({
  project,
  tableColumns,
  mapLayer: inputMapLayer,
  isNew,
  onCreateMapLayer,
  onUpdateMapLayer,
  onDeleteMapLayer,
  onCancel,
}) => {
  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);

  const [showConfirmIsDefaultLayer, setShowConfirmIsDefaultLayer] =
    useState<Boolean>(false);

  const user = useContext<User | undefined>(UserContext);

  const [validatorManager] = useState<ValidatorManager>(new ValidatorManager());

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [fieldOptions, setFieldOptions] = useState<
    DropdownOption<TableColum>[]
  >([]);
  const [selectedFieldOption, setSelectedFieldOption] =
    useState<DropdownOption<TableColum>>();

  const [mapLayer, setMapLayer] = useState<MapLayer>(new MapLayer());

  const setNewThematicLayer = useCallback(
    (
      mapLayer: MapLayer,
      tableColumn: TableColum,
      thematicMapLayerRanges: ThematicMapLayerRange[]
    ) => {
      if (!mapLayer.thematicMapLayer) {
        return;
      }
      const newMapLayer = { ...mapLayer };
      newMapLayer.thematicMapLayer = { ...mapLayer.thematicMapLayer };
      newMapLayer.thematicMapLayer.fieldName = tableColumn.columnName;

      if (isDataTypeContinous(tableColumn.dataType)) {
        newMapLayer.thematicMapLayer.rangeType =
          ThematicMapLayerRangeType.Continuous;
      } else if (isDataTypeDiscrete(tableColumn.dataType)) {
        newMapLayer.thematicMapLayer.rangeType =
          ThematicMapLayerRangeType.Discrete;
      }

      newMapLayer.thematicMapLayer.thematicMapLayerRanges =
        thematicMapLayerRanges;

      setMapLayer(newMapLayer);
    },
    []
  );

  const setFieldOptionsFromMapLayer = useCallback(
    (
      mapLayer: MapLayer,
      tableColumns: TableColum[],
      fieldOptions: DropdownOption<TableColum>[]
    ) => {
      const layerFieldName = mapLayer.thematicMapLayer?.fieldName;

      const layerTableColumn = tableColumns.find(
        (c) => c.columnName === layerFieldName
      );

      if (layerTableColumn) {
        const layerFieldOption = {
          value: layerTableColumn,
          label: layerTableColumn.displayName,
        };

        setSelectedFieldOption(layerFieldOption);
      } else {
        const selectedFieldOption = fieldOptions[0];
        setSelectedFieldOption(selectedFieldOption);

        if (selectedFieldOption.value) {
          setNewThematicLayer(mapLayer, selectedFieldOption.value, []);
        }
      }
    },
    [setNewThematicLayer]
  );

  useEffect(() => {
    setMapLayer(inputMapLayer);

    const options: DropdownOption<TableColum>[] = tableColumns.map((c) => {
      return { value: c, label: c.displayName };
    });

    setFieldOptions(options);

    setFieldOptionsFromMapLayer(inputMapLayer, tableColumns, options);

    if (!isNew) {
      validatorManager.setIsValid(validator.layerName, true);
    }
  }, [
    project,
    tableColumns,
    inputMapLayer,
    isNew,
    setFieldOptionsFromMapLayer,
    validatorManager,
  ]);

  const onThematicMapLayerRangesChanged = (
    thematicMapLayerRanges: ThematicMapLayerRange[]
  ) => {
    if (!mapLayer.thematicMapLayer) {
      return;
    }

    if (!selectedFieldOption || !selectedFieldOption.value) {
      return;
    }

    setNewThematicLayer(
      mapLayer,
      selectedFieldOption.value,
      thematicMapLayerRanges
    );
  };

  const onLayerNameChanged = (event: any) => {
    const newValue = event.target.value;

    const newMapLayer = { ...mapLayer, layerName: newValue };
    setMapLayer(newMapLayer);

    const isValid = newValue.length !== 0;
    validatorManager.setIsValid(validator.layerName, isValid);
  };

  const onFiledOptionChaged = async (newValue: DropdownOption<TableColum>) => {
    setSelectedFieldOption(newValue);

    if (!newValue.value) {
      return;
    }

    setNewThematicLayer(mapLayer, newValue.value, []);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    if (!validatorManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        await onCreateMapLayer(mapLayer);
      } else {
        await onUpdateMapLayer(mapLayer);
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = async () => {
    setShowDeleteWindow(false);

    onDeleteMapLayer(mapLayer);
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const isDataTypeContinous = (dataType: TableColumnDataType) => {
    return dataType === TableColumnDataType.Number;
  };

  const isDataTypeDiscrete = (dataType: TableColumnDataType) => {
    return (
      dataType === TableColumnDataType.String ||
      dataType === TableColumnDataType.Boolean
    );
  };

  const onIsDefaultLayerChanged = (event: any) => {
    const checked = event.target.checked;

    if (checked) {
      onConfirmIsDefaultLayer();
      return;
    }

    const newMapLayer = { ...mapLayer, isDefault: false };
    setMapLayer(newMapLayer);
  };

  const onConfirmIsDefaultLayer = () => {
    setShowConfirmIsDefaultLayer(true);
  };

  const onOkIsDefaultLayer = () => {
    setShowConfirmIsDefaultLayer(false);

    const newMapLayer = { ...mapLayer, isDefault: true };
    setMapLayer(newMapLayer);
  };

  const onCancelIsDefaultLayer = () => {
    setShowConfirmIsDefaultLayer(false);
  };

  const onResetToDefault = () => {
    if (!mapLayer.linkedMapLayer) {
      return;
    }

    const defaultMapLayer = structuredClone(mapLayer.linkedMapLayer);

    if (!defaultMapLayer) {
      return;
    }

    defaultMapLayer.isDefault = false;
    defaultMapLayer.isDefaultChanged = false;

    defaultMapLayer.idMapLayer = mapLayer.idMapLayer;
    defaultMapLayer.idUser = mapLayer.idUser;
    defaultMapLayer.idLinkedMapLayer = mapLayer.idLinkedMapLayer;
    defaultMapLayer.linkedMapLayer = mapLayer.linkedMapLayer;

    // network layer

    defaultMapLayer.networkMapLayer = mapLayer.networkMapLayer;

    // thematic layer

    setFieldOptionsFromMapLayer(defaultMapLayer, tableColumns, fieldOptions);

    if (defaultMapLayer.thematicMapLayer && mapLayer.thematicMapLayer) {
      defaultMapLayer.thematicMapLayer.idThematicMapLayer =
        mapLayer.thematicMapLayer.idThematicMapLayer;
      defaultMapLayer.thematicMapLayer.idMapLayer =
        mapLayer.thematicMapLayer.idMapLayer;

      // mark thematic ranges as new
      for (const range of defaultMapLayer.thematicMapLayer
        .thematicMapLayerRanges) {
        range.idThematicMapLayerRange = 0;
        range.idThematicMapLayer = mapLayer.thematicMapLayer.idThematicMapLayer;
      }
    }

    setMapLayer(defaultMapLayer);
  };

  const onEditDefaultLayer = () => {
    const defaultMapLayer = structuredClone(mapLayer.linkedMapLayer);

    if (!defaultMapLayer) {
      return;
    }
    setMapLayer(defaultMapLayer);
  };

  return (
    <div style={{ background: "white", padding: "10px", minWidth: "300px" }}>
      <form
        className="input-form"
        onSubmit={onSubmit}
        onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
      >
        <div className="row">
          <div className="mb-3 d-flex justify-content-between">
            <div>
              <b>
                {isNew
                  ? "New Layer"
                  : !mapLayer.isDefault
                  ? "Edit Layer"
                  : "Edit Project Default Layer"}
              </b>
              {mapLayer.linkedMapLayer?.isDefault && (
                <Tooltip
                  className="ms-2 align-self-center"
                  title={
                    mapLayer.isDefaultChanged
                      ? "Default Layer Changed, reset to default"
                      : "Default Layer, can't be deleted"
                  }
                >
                  <LockIcon
                    style={{
                      fontSize: "14px",
                      color: mapLayer.isDefaultChanged ? "orange" : "initial",
                    }}
                  />
                </Tooltip>
              )}
            </div>
            {user?.isPortalAdmin && !mapLayer.linkedMapLayer && (
              <div className="d-flex align-items-center ms-3">
                <div className="badge bg-secondary me-2">PORTAL ADMIN</div>
                <label className="form-check-label" htmlFor="isDefaultLayer">
                  Project Default Layer
                </label>
                <div className="form-switch form-switch-right-text">
                  <input
                    className="form-check-input form-switch-right-text"
                    type="checkbox"
                    checked={mapLayer.isDefault}
                    onChange={onIsDefaultLayerChanged}
                    id="isDefaultLayer"
                  />
                </div>
                {showConfirmIsDefaultLayer && (
                  <ModalWindow
                    title="Set Project Default Map Layer"
                    onOk={onOkIsDefaultLayer}
                    onCancel={onCancelIsDefaultLayer}
                  >
                    <div>
                      Are you sure you want to set the map layer as a project
                      default map layer to be visible by all users?
                    </div>
                  </ModalWindow>
                )}
              </div>
            )}
            {user?.isPortalAdmin &&
              mapLayer.linkedMapLayer &&
              mapLayer.linkedMapLayer.isDefault &&
              !mapLayer.isDefault && (
                <div className="d-flex align-items-center ms-3">
                  <div className="badge bg-secondary me-2">PORTAL ADMIN</div>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={onEditDefaultLayer}
                  >
                    Edit Default Layer
                  </button>
                </div>
              )}
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            <label htmlFor="layerName" className="form-label">
              Layer Name
            </label>
            <div className="input-group">
              <Validator
                name={validator.layerName}
                errorMessage="Please enter a layer name."
                validationManager={validatorManager}
              >
                <input
                  type="text"
                  className="form-control"
                  id="layerName"
                  value={mapLayer.layerName}
                  onChange={onLayerNameChanged}
                />
              </Validator>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            <label htmlFor="field" className="form-label">
              Field
            </label>
            <div className="d-flex align-items-center mb-3">
              <Dropdown
                minWidth="200px"
                options={fieldOptions}
                value={selectedFieldOption}
                onChange={(newValue: any) => onFiledOptionChaged(newValue)}
                disabled={mapLayer.linkedMapLayer}
              />
              {selectedFieldOption && (
                <div className="ms-2">
                  {selectedFieldOption.value &&
                    unitConverter.getSymbol(
                      selectedFieldOption.value.unitMeasure
                    )}
                </div>
              )}
            </div>
            {selectedFieldOption &&
              selectedFieldOption.value &&
              isDataTypeContinous(selectedFieldOption.value.dataType) && (
                <ThematicRangesEditor
                  tableColumn={selectedFieldOption.value}
                  thematicMapLayerRanges={
                    mapLayer.thematicMapLayer?.thematicMapLayerRanges ?? []
                  }
                  onThematicMapLayerRangesChanged={
                    onThematicMapLayerRangesChanged
                  }
                />
              )}
            {selectedFieldOption &&
              selectedFieldOption.value &&
              isDataTypeDiscrete(selectedFieldOption.value.dataType) && (
                <ThematicValuesEditor
                  project={project}
                  tableColumn={selectedFieldOption.value}
                  thematicMapLayerRanges={
                    mapLayer.thematicMapLayer?.thematicMapLayerRanges ?? []
                  }
                  onThematicMapLayerRangesChanged={
                    onThematicMapLayerRangesChanged
                  }
                />
              )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <button type="submit" className="btn btn-sm btn-primary">
              {isNew ? "Create" : "Save"}
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </button>
            <div className="col-md-12 text-center mt-3">
              {errorMessage !== "" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </div>
          {!isNew && !mapLayer.linkedMapLayer && (
            <div>
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={onDeleteConfirm}
              >
                Delete
              </button>
            </div>
          )}
          {mapLayer.linkedMapLayer && (
            <div>
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={onResetToDefault}
              >
                Reset to Default
              </button>
            </div>
          )}
        </div>
      </form>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>
            {`Are you sure you want to delete the map layer: ${mapLayer?.layerName}?`}
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default ThematicMapLayerEdit;
