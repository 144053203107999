import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Navigator from "../models/navigator";
import Map, { MapRef } from "../components/map/Map";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RGL, { Layout, WidthProvider } from "react-grid-layout";
import GridWindow from "../components/common/GridWindow";
import Camera from "../components/camera/Camera";
import CameraPano, { CameraPanoRef } from "../components/camera/CameraPano";
import Table, { TableRef } from "../components/table/Table";
import NavBarHome from "../components/navbar/NavBarHome";
import WindowIds from "../models/layout/windowIds";
import ImageStream from "../models/imageStream";
import WindowVisibility from "../models/layout/windowVisibility";
import localStorageService from "../services/localStorageService";
import LogService from "../services/logService";
import LogDirectory from "../services/logDirectory";
import imageService from "../services/imageService";
import roadService from "../services/roadService";
import ProjectInfo from "../models/projectInfo";
import tooltips from "../util/tooltips";
import appSettings from "../appSettings";
import WorkspaceManager from "../models/workspaceManager";
import User from "../models/user";
import UserContext from "../contexts/userContext";
import { useNavigate } from "react-router-dom";
import projectService from "../services/projectService";
import ProjectCollectionInfo from "../models/projectCollectionInfo";
import settingService from "../services/settingsService";
import Settings from "../models/settings";
import screenHelper from "../util/screenHelper";
import pageHelper from "../util/pageHelper";
import workspaceService from "../services/workspaceService";
import CameraPavement, {
  CameraPavementRef,
} from "../components/camera/CameraPavement";
import AppUrlParamsContext from "../contexts/appUrlParamsContext";
import mapService from "../services/mapService";
import GpsPosition from "../models/gpsPosition";
import ModalWindow from "../components/common/overlays/ModalWindow";
import NavBar from "../components/navbar/NavBar";
import unitConverter from "../util/units/unitConverter";
import BusyIndicator from "../components/common/overlays/BusyIndicator";

const logService = new LogService(LogDirectory.EnableAppLogging);

const ReactGridLayout = WidthProvider(RGL);

const Home: React.FC = () => {
  const navigator: Navigator = useMemo(() => new Navigator(), []);

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [isBusyMessage, setIsBusyMessage] = useState<string>();

  const [project, setProject] = useState<ProjectInfo>(new ProjectInfo());
  const [projectCollection, setProjectCollection] =
    useState<ProjectCollectionInfo>(new ProjectCollectionInfo());

  const user = useContext<User | undefined>(UserContext);

  const [settings, setSettings] = useState<Settings>(new Settings());

  const [gridColsCount] = useState<number>(12);
  const [gridRowHeight, setGridRowHeight] = useState<number>();

  const [layout, setLayout] = useState<Layout[]>([]);
  const [windowVisibilities, setWindowVisibilities] = useState<
    WindowVisibility[]
  >([]);

  const [cameras, setCameras] = useState<ImageStream[]>([]);

  const [workspaceManager] = useState<WorkspaceManager>(new WorkspaceManager());

  const navigate = useNavigate();

  const mapRef = useRef<MapRef>(null);
  const panoCameraRef = useRef<CameraPanoRef>(null);
  const pavementCameraRef = useRef<CameraPavementRef>(null);
  const tableRef = useRef<TableRef>(null);

  const appUrlParams = useContext(AppUrlParamsContext);
  const [showUrlInitErrorWindow, setShowUrlInitErrorWindow] =
    useState<boolean>(false);
  const [urlInitErrorMessage, setUrlInitErrorMessage] = useState<string>("");

  useEffect(() => {
    logService.log("homePageInit-eventListenerInit");

    pageHelper.setAutoScrollbars();

    resizeGridRow();

    let isWindowResizeInProgress: boolean = false;

    const onWindowResize = (event: any) => {
      if (isWindowResizeInProgress) {
        return;
      }

      isWindowResizeInProgress = true;

      logService.log("window:onWindowResize");

      setTimeout(() => {
        onResize();
        resizeGridRow();
        isWindowResizeInProgress = false;
      }, 500);
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  useEffect(() => {
    console.log("homePageInit-initProject");

    const init = async () => {
      let projectCollection: ProjectCollectionInfo | undefined = undefined;
      let project: ProjectInfo | undefined = undefined;

      const { data: projectCollections } =
        await projectService.getProjectCollectionsInfos();

      if (appUrlParams.hasUrlParams) {
        console.log("homePageInit-initProject-usingUrlPramaters");

        let projectFound = false;

        for (const prjCollection of projectCollections) {
          for (const prj of prjCollection.projects) {
            if (
              prj.projectCode.toLowerCase() ===
              appUrlParams.project.toLowerCase()
            ) {
              projectFound = true;

              projectCollection = prjCollection;
              project = prj;
              break;
            }
          }
          if (projectFound) {
            break;
          }
        }

        if (!projectFound) {
          showInitErrorWindow(
            "Cannot initialize in the project specified in the url."
          );

          // flow is continued in onInitErrorWindowClose()

          return;
        }
      } else {
        let idProjectCollection = localStorageService.getIdProjectCollection();

        if (!idProjectCollection) {
          navigate("/projects");
          return;
        }

        const prjCollection = projectCollections.find(
          (c) => c.idProjectCollection === idProjectCollection
        );

        if (!prjCollection || prjCollection.projects.length === 0) {
          navigate("/projects");
          return;
        }

        projectCollection = prjCollection;

        let idProject = localStorageService.getIdProject();
        if (idProject) {
          project = prjCollection.projects.find(
            (p) => p.idProject === idProject
          );
        }

        if (!project) {
          project = prjCollection.projects[0];
          localStorageService.setIdProject(project.idProject);
        }
      }

      if (!projectCollection || !project) {
        return;
      }

      setProjectCollection(projectCollection);
      setProject(project);
    };

    init();

    return () => {
      navigator.navigatorPosition = null;
    };
  }, [navigator, navigate, appUrlParams]);

  useEffect(() => {
    if (project.idProject === 0 || !user) {
      return;
    }

    console.log("homePageInit-initLocation");

    const initNavigatorPosition = async (navigator: Navigator) => {
      let { data: navigatorPosition } =
        await roadService.getProjectDefaultLocation(project.idProject);

      navigator.move(navigatorPosition);
    };

    const onSettingsChanged = async (settings: Settings) => {
      if (user?.isDemoUser) {
        return;
      }

      await settingService.saveSettings(settings);
    };

    const init = async () => {
      const defaultSettings = new Settings();

      defaultSettings.showChainage = project.showChainageDefault;

      defaultSettings.showPavementDistress = false;
      defaultSettings.showPavementDistressBoxes = false;
      defaultSettings.showPavementDistressLabels = false;
      defaultSettings.showPavementEvents = false;
      defaultSettings.showPavementMarkingsDuringPlayback = false;

      const settings = await settingService.getSettings(defaultSettings);
      setSettings(settings);

      settings.onSettingsChanged = onSettingsChanged;

      workspaceManager.clear();

      const { data: imageStreams } = await imageService.getImageStreams(
        project.idProject
      );

      setCameras(imageStreams);

      workspaceManager.setUser(user);

      await workspaceManager.initWorkspace(imageStreams, project.idProject);
      setLayout(workspaceManager.layout);
      setWindowVisibilities(workspaceManager.windowVisibilities);

      tooltips.initTooltips();

      const isBusyMessage =
        "Initializing using URL parameters... Please wait...";

      if (appUrlParams.hasUrlParams) {
        if (appUrlParams.hasLatLon()) {
          const gpsPosition = new GpsPosition();
          gpsPosition.latitude = appUrlParams.latitude;
          gpsPosition.longitude = appUrlParams.longitude;

          setIsBusy(true);
          setIsBusyMessage(isBusyMessage);

          const { data: navigatorPosition } =
            await mapService.getClosestNavigatorPositionForGpsPosition(
              project.idProject,
              gpsPosition,
              7,
              []
            );

          setIsBusy(false);
          setIsBusyMessage(undefined);

          if (navigatorPosition) {
            navigator.move(navigatorPosition);
          } else {
            showInitErrorWindow(
              "Cannot initialize in the gps position specified in the url."
            );
            initNavigatorPosition(navigator);
          }
        } else if (
          appUrlParams.hasNetworkPosition &&
          appUrlParams.networkPosition
        ) {
          const networkPosition = { ...appUrlParams.networkPosition };
          networkPosition.road = decodeURI(networkPosition.road);
          networkPosition.direction = decodeURI(networkPosition.direction);
          networkPosition.segmentId = decodeURI(networkPosition.segmentId);
          networkPosition.shapeId = decodeURI(networkPosition.shapeId);
          networkPosition.chainage = unitConverter.milesToMeters(
            networkPosition.chainage
          );

          setIsBusy(true);
          setIsBusyMessage(isBusyMessage);

          const { data: navigatorPosition } =
            await roadService.getNavigatorPositionForNetworkLocation(
              project.idProject,
              networkPosition
            );

          setIsBusy(false);
          setIsBusyMessage(undefined);

          if (navigatorPosition) {
            navigator.move(navigatorPosition);
          } else {
            showInitErrorWindow(
              "Cannot initialize in the network position specified in the url."
            );
            initNavigatorPosition(navigator);
          }
        } else {
          initNavigatorPosition(navigator);
        }

        appUrlParams.clear();
      } else {
        initNavigatorPosition(navigator);
      }
    };

    init();
  }, [navigator, project, user, workspaceManager, appUrlParams, setIsBusy]);

  const onInitErrorWindowClose = () => {
    setShowUrlInitErrorWindow(false);
    setUrlInitErrorMessage("");

    appUrlParams.clear();

    if (project.idProject === 0) {
      navigate("/projects");
    }
  };

  const showInitErrorWindow = (errorMessage: string) => {
    setUrlInitErrorMessage(errorMessage);
    setShowUrlInitErrorWindow(true);
  };

  const onWindowVisibilityChanged = (windowId: string, isVisible: boolean) => {
    workspaceManager.onWindowVisibilityChanged(windowId, isVisible);
    setLayout(workspaceManager.layout);
    setWindowVisibilities(workspaceManager.windowVisibilities);
  };

  const onGridResizeStart = (layout: Layout[]) => {
    logService.log("layout:onGridResizeStart");
  };

  const onGridResizeStop = (layout: Layout[]) => {
    logService.log("layout:onGridResizeStop");

    if (cameras.find((c) => c.isPanoramic)) {
      resizeCameraPano(layout);
    }

    onResize();
  };

  const resizeCameraPano = (newLayout: Layout[]) => {
    const panoCameraName = cameras.find((c) => c.isPanoramic)?.cameraName || "";
    const id = WindowIds.getCameraId(panoCameraName);

    const newPanoLayout = newLayout.find((i) => i.i === id);
    const oldPanoLayout = layout.find((i) => i.i === id);

    if (newPanoLayout && oldPanoLayout) {
      const isPanoLayoutSizeChanged =
        oldPanoLayout.w !== newPanoLayout.w ||
        oldPanoLayout.h !== newPanoLayout.h;
      if (isPanoLayoutSizeChanged) {
        panoCameraRef?.current?.refresh();
      }
    }
  };

  const resizeGridRow = () => {
    if (screenHelper.is4k()) {
      setGridRowHeight(100);
    } else {
      setGridRowHeight(97);
    }
  };

  const onResize = () => {
    tableRef?.current?.refresh();
    mapRef?.current?.refresh();
    pavementCameraRef?.current?.refresh();
  };

  const onLayoutChanged = (layout: Layout[]) => {
    logService.log("layout:onLayoutChanged");
    workspaceManager.onLayoutChanged(layout);
    setLayout(workspaceManager.layout);
  };

  const getWindowVisibility = (
    windowId: string
  ): WindowVisibility | undefined => {
    return windowVisibilities.find((wv) => wv.windowId === windowId);
  };

  const onYearChanged = async (project: ProjectInfo) => {
    await navigator.setFilters(project.idProject, []);
    setProject(project);
    localStorageService.setIdProject(project.idProject);
  };

  const onResetLayout = async () => {
    await workspaceManager.resetWorkspace(cameras, project.idProject);
    setLayout(workspaceManager.layout);
    setWindowVisibilities(workspaceManager.windowVisibilities);
  };

  const onSetProjectDefaultLayout = async () => {
    await workspaceService.setProjectDefaultWorkspace(
      workspaceManager.workspace
    );
  };

  const onSetProjectDefaultLocation = async () => {
    if (!navigator.navigatorPosition) {
      return;
    }

    await roadService.setProjectDefaultLocation(
      project.idProject,
      navigator.navigatorPosition
    );
  };

  if (project.idProject === 0) {
    return (
      <>
        <ToastContainer />
        <div className="version">{appSettings.appVersion}</div>
        <NavBar />
        {showUrlInitErrorWindow && (
          <ModalWindow
            title="URL Initialization"
            onOk={() => onInitErrorWindowClose()}
            hasCancel={false}
          >
            <div>{urlInitErrorMessage}</div>
          </ModalWindow>
        )}
      </>
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="version">{appSettings.appVersion}</div>
      <NavBarHome
        navigator={navigator}
        project={project}
        projectCollection={projectCollection}
        cameras={cameras}
        windowVisibilities={windowVisibilities}
        onWindowVisibilityChanged={onWindowVisibilityChanged}
        onYearChanged={onYearChanged}
        settings={settings}
        onResetLayout={onResetLayout}
        onSetProjectDefaultLayout={onSetProjectDefaultLayout}
        onSetProjectDefaultLocation={onSetProjectDefaultLocation}
      />
      <div id="content">
        <ReactGridLayout
          className="layout"
          layout={layout}
          cols={gridColsCount}
          rowHeight={gridRowHeight}
          draggableHandle=".grid-window-title"
          onResizeStart={onGridResizeStart}
          onResizeStop={onGridResizeStop}
          onLayoutChange={onLayoutChanged}
          margin={[10, 10]}
        >
          {cameras.map(
            (camera) =>
              getWindowVisibility(WindowIds.getCameraId(camera.cameraName))
                ?.isVisibile && (
                <div key={WindowIds.getCameraId(camera.cameraName)}>
                  <GridWindow
                    windowId={WindowIds.getCameraId(camera.cameraName)}
                    title={`Camera ${camera.cameraName}`}
                    onClose={(windowId: string) =>
                      onWindowVisibilityChanged(windowId, false)
                    }
                  >
                    {!camera.isPanoramic && !camera.isPavement && (
                      <Camera
                        cameraName={camera.cameraName}
                        navigator={navigator}
                        project={project}
                        settings={settings}
                      />
                    )}
                    {camera.isPavement && (
                      <CameraPavement
                        ref={pavementCameraRef}
                        cameraName={camera.cameraName}
                        navigator={navigator}
                        project={project}
                        settings={settings}
                      />
                    )}
                    {camera.isPanoramic && (
                      <CameraPano
                        ref={panoCameraRef}
                        cameraName={camera.cameraName}
                        navigator={navigator}
                        project={project}
                      />
                    )}
                  </GridWindow>
                </div>
              )
          )}
          {getWindowVisibility(WindowIds.MapId)?.isVisibile && (
            <div key={WindowIds.MapId}>
              <GridWindow
                windowId={WindowIds.MapId}
                title="Map"
                onClose={(windowId: string) =>
                  onWindowVisibilityChanged(windowId, false)
                }
              >
                <Map ref={mapRef} navigator={navigator} project={project} />
              </GridWindow>
            </div>
          )}
          {getWindowVisibility(WindowIds.TableId)?.isVisibile && (
            <div key={WindowIds.TableId}>
              <GridWindow
                windowId={WindowIds.TableId}
                title="Roads"
                onClose={(windowId: string) =>
                  onWindowVisibilityChanged(windowId, false)
                }
              >
                <Table
                  ref={tableRef}
                  navigator={navigator}
                  project={project}
                  settings={settings}
                />
              </GridWindow>
            </div>
          )}
        </ReactGridLayout>
        {showUrlInitErrorWindow && (
          <ModalWindow
            title="URL Initialization"
            onOk={() => onInitErrorWindowClose()}
            hasCancel={false}
          >
            <div>{urlInitErrorMessage}</div>
          </ModalWindow>
        )}
        {isBusy && <BusyIndicator isBusyMessage={isBusyMessage} />}
      </div>
    </>
  );
};

export default Home;
