import Filter from "../models/filter";
import TableColum from "../models/tableColumn";
import httpService from "./httpService";

const apiEndpoint = "/table";

const getTableColumns = (idProject: number) => {
  return httpService.get<TableColum[]>(
    `${apiEndpoint}/getTableColumns?idProject=${idProject}`
  );
};

const getTableColumnValues = (idProject: number, columnName: string) => {
  return httpService.get<string[]>(
    `${apiEndpoint}/getTableColumnValues?idProject=${idProject}&columnName=${columnName}`
  );
};

const getTableData = (
  idProject: number,
  startIndex: number,
  stopIndex: number,
  filters: Filter[]
) => {
  return httpService.post(`${apiEndpoint}/getTableData`, {
    idProject,
    startIndex,
    stopIndex,
    filters,
  });
};

const getTableRowIndex = (
  idProject: number,
  idRoad: number,
  idSession: number,
  distance: number,
  filters: Filter[]
) => {
  return httpService.post<number>(`${apiEndpoint}/getTableRowIndex`, {
    idProject,
    idRoad,
    idSession,
    distance,
    filters,
  });
};

const getTableRowsCount = (idProject: number, filters: Filter[]) => {
  return httpService.post<number>(`${apiEndpoint}/getTableRowsCount`, {
    idProject,
    filters,
  });
};

const tableService = {
  getTableColumns,
  getTableColumnValues,
  getTableData,
  getTableRowIndex,
  getTableRowsCount,
};

export default tableService;
