import { SketchPicker } from "react-color";

const ColorPicker: React.FC<{
  color: any;
  onColorChange: any;
  onClickOutside: any;
}> = ({ color, onColorChange, onClickOutside }) => {
  return (
    <div>
      <>
        {/* cover to click on it and close color picker */}
        <div
          style={{
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
          }}
          onClick={onClickOutside}
        />
        <div style={{ position: "absolute" }}>
          <SketchPicker
            color={color}
            onChange={(color) => onColorChange(color)}
          />
        </div>
      </>
    </div>
  );
};

export default ColorPicker;
