import Filter from "../models/filter";
import NavigatorPosition from "../models/navigatorPosition";
import NetworkPosition from "../models/networkPosition";
import ProjectLocatorValue from "../models/projectLocatorValue";
import RoadLocatorSegment from "../models/roadLocatorSegment";
import RoadMilepostSegment from "../models/roadMilepostSegment";
import SessionInfo from "../models/sessionInfo";
import httpService from "./httpService";

const apiEndpoint = "/roads";

const setProjectDefaultLocation = (
  idProject: number,
  navigatorPosition: NavigatorPosition
) => {
  return httpService.post(`${apiEndpoint}/setProjectDefaultLocation`, {
    idProject,
    navigatorPosition,
  });
};

const getProjectDefaultLocation = (idProject: number) => {
  return httpService.get<NavigatorPosition>(
    `${apiEndpoint}/getProjectDefaultLocation?idProject=${idProject}`
  );
};

const getFirstNavigatorPositionForFilters = (
  idProject: number,
  filters: Filter[],
  keepCurrentPosition: boolean,
  currentPosition: NavigatorPosition | null
) => {
  //const data = { filters };
  return httpService.post<NavigatorPosition>(
    `${apiEndpoint}/getFirstNavigatorPositionForFilters`,
    {
      idProject,
      filters,
      keepCurrentPosition,
      currentPosition,
    }
  );
};

const getRoadLocatorSegments = (
  idProject: number,
  idRoad: number,
  filters: Filter[]
) => {
  const promise = httpService.post<RoadLocatorSegment[]>(
    `${apiEndpoint}/getRoadLocatorSegments`,
    {
      idProject,
      idRoad,
      filters,
    }
  );
  const dataPromise = promise.then((response) => {
    const items: RoadLocatorSegment[] = [];
    for (const item of response.data) {
      items.push(RoadLocatorSegment.clone(item));
    }
    return items;
  });
  return dataPromise;
};

const getRoadMilepostSegments = (
  idProject: number,
  idRoad: number,
  filters: Filter[]
) => {
  return httpService.post<RoadMilepostSegment[]>(
    `${apiEndpoint}/getRoadMilepostSegments`,
    {
      idProject,
      idRoad,
      filters,
    }
  );
};

const getIdRoadsForFilters = (idProject: number, filters: Filter[]) => {
  return httpService.post<number[]>(`${apiEndpoint}/getIdRoadsForFilters`, {
    idProject,
    filters,
  });
};

const getSessionInfo = (idProject: number, idSession: number) => {
  return httpService.get<SessionInfo>(
    `${apiEndpoint}/getSessionInfo?idProject=${idProject}&idSession=${idSession}`
  );
};

const getLocatorValues = (
  navigatorPosition: NavigatorPosition,
  roadLocatorSegments: RoadLocatorSegment[]
): ProjectLocatorValue[] => {
  let locatorValues: ProjectLocatorValue[] = [];
  for (const roadLocatorSegment of roadLocatorSegments) {
    if (
      roadLocatorSegment.idSession === navigatorPosition.idSession &&
      navigatorPosition.distance >= roadLocatorSegment.startDistance &&
      navigatorPosition.distance <= roadLocatorSegment.endDistance
    ) {
      locatorValues = roadLocatorSegment.locatorValues;
      break;
    }
  }
  return locatorValues;
};

const getNetworkPositionForNavigatorPosition = (
  idProject: number,
  navigatorPosition: NavigatorPosition
) => {
  return httpService.post<NetworkPosition>(
    `${apiEndpoint}/getNetworkPositionForNavigatorPosition`,
    { idProject, navigatorPosition }
  );
};

const getNavigatorPositionForNetworkLocation = (
  idProject: number,
  networkPosition: NetworkPosition
) => {
  return httpService.post<NavigatorPosition>(
    `${apiEndpoint}/getNavigatorPositionForNetworkLocation`,
    { idProject, networkPosition }
  );
};

const roadService = {
  getFirstNavigatorPositionForFilters,
  getRoadLocatorSegments,
  getRoadMilepostSegments,
  getIdRoadsForFilters,
  getSessionInfo,
  getLocatorValues,
  getNetworkPositionForNavigatorPosition,
  getNavigatorPositionForNetworkLocation,
  setProjectDefaultLocation,
  getProjectDefaultLocation,
};

export default roadService;
