import ThematicMapLayerRange from "./thematicMapLayerRange";
import ThematicMapLayerRangeType from "./thematicMapLayerRangeType";

export default class ThematicMapLayer {
  public idThematicMapLayer: number = 0;
  public idMapLayer: number = 0;
  public fieldName: string = "";
  public rangeType: ThematicMapLayerRangeType =
    ThematicMapLayerRangeType.Continuous;

  public thematicMapLayerRanges: ThematicMapLayerRange[] = [];
}
