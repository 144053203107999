import { useEffect, useState } from "react";
import ProjectCollectionInfo from "../../models/projectCollectionInfo";
import ProjectInfo from "../../models/projectInfo";
import LogDirectory from "../../services/logDirectory";
import LogService from "../../services/logService";
import Dropdown from "../common/Dropdown";
import DropdownOption from "../common/DropdownOption";

const logService = new LogService(LogDirectory.EnableYearLogging);

const Year: React.FC<{
  project: ProjectInfo;
  projectCollection: ProjectCollectionInfo;
  onYearChanged: any;
}> = ({ project, projectCollection, onYearChanged }) => {
  const [years, setYears] = useState<DropdownOption<ProjectInfo>[]>([]);
  const [year, setYear] = useState<DropdownOption<ProjectInfo>>();

  useEffect(() => {
    logService.log("year:init()");

    const dropdownOptions: DropdownOption<ProjectInfo>[] = [];
    for (const project of projectCollection.projects) {
      const newOption = new DropdownOption<ProjectInfo>();
      newOption.value = project;
      newOption.label = project.year;
      dropdownOptions.push(newOption);
    }
    setYears(dropdownOptions);

    const currentYearOption = dropdownOptions.find(
      (o) => o.value?.idProject === project.idProject
    );
    if (currentYearOption) {
      setYear(currentYearOption);
    }
  }, [project, projectCollection]);

  const onYearChange = (newValue: DropdownOption<ProjectInfo>) => {
    onYearChanged(newValue.value);
  };

  return (
    <div className="menu-dropdown menu-item-spacing-right">
      <div className="locators-title">Year</div>
      <Dropdown
        options={years}
        value={year}
        onChange={(newValue: any) => onYearChange(newValue)}
      />
    </div>
  );
};

export default Year;
