import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import userService from "../../services/userService";
import BoxContent from "../../components/common/BoxContent";
import formHelper from "../../util/formHelper";
import ModalWindow from "../../components/common/overlays/ModalWindow";
import SignupApproval from "../../models/signupApproval";

const SignupApprovalEdit: React.FC<{}> = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [signupApproval, setSignupApproval] = useState<SignupApproval>(
    new SignupApproval()
  );

  useEffect(() => {
    const initEdit = async (idSignupApproval: number) => {
      const { data: signupApproval } = await userService.getSignupApprovalById(
        idSignupApproval
      );

      setSignupApproval(signupApproval);
    };

    const idSignupApproval = Number(idParam);
    initEdit(idSignupApproval);
  }, [idParam]);

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = async () => {
    setShowDeleteWindow(false);
    await userService.deleteSignupApproval(signupApproval.idSignupApproval);
    navigateAway();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onCancel = () => {
    navigateAway();
  };

  const navigateAway = () => {
    navigate("/admin/signup-approvals");
  };

  return (
    <BoxContent title="Edit Signup Approval" useFullWidth>
      <div className="d-flex mb-3">
        <div style={{ fontWeight: "bold", marginRight: "10px" }}>
          Organization
        </div>
        <div>{signupApproval.organization?.organizationName}</div>
      </div>
      <form
        className="input-form"
        onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
      >
        <div className="row">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <div>
              <label htmlFor="email" className="form-label">
                {signupApproval.user?.email}
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              // style={{ marginLeft: "10px" }}
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-danger"
              onClick={onDeleteConfirm}
            >
              Delete
            </button>
          </div>
        </div>
        <div className="col-md-12 text-center mt-3">
          {errorMessage !== "" && (
            <div className="error-message">{errorMessage}</div>
          )}
        </div>
      </form>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>{`Are you sure you want to delete the signup approval?`}</div>
        </ModalWindow>
      )}
    </BoxContent>
  );
};

export default SignupApprovalEdit;
