import DropdownOption from "../components/common/DropdownOption";

export default class ThematicMapLayerRange {
  public idThematicMapLayerRange: number = 0;
  public idThematicMapLayer: number = 0;
  public rangeLabel: string = "";
  public rangeColor: string = "";
  public hasRangeStart: boolean = false;
  public hasRangeEnd: boolean = false;
  public rangeStartValue: number = 0;
  public rangeEndValue: number = 0;
  public rangeStartDisplayValue: number = 0; // used on ui
  public rangeEndDisplayValue: number = 0; // used on ui
  public rangeValue: string = "";
  public index: number = 0;

  // used on ui only
  public showColorPicker: boolean = false;
  public selectedValueOption: DropdownOption<string> | undefined = undefined;
}
