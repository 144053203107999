import Measurement from "./measurement";
import Unit from "./unit";
import UnitMeasure from "./unitMeasure";

const unitMeasures: UnitMeasure[] = [
  //length
  UnitMeasure.create(Measurement.Length, Unit.Meter, "m"),
  UnitMeasure.create(Measurement.Length, Unit.Centimeter, "cm", 100),
  UnitMeasure.create(Measurement.Length, Unit.Millimeter, "mm", 1000),
  UnitMeasure.create(Measurement.Length, Unit.Micron, "µm", 1000000),
  UnitMeasure.create(Measurement.Length, Unit.Hectometer, "hm", 0.01),
  UnitMeasure.create(Measurement.Length, Unit.Kilometer, "km", 0.001),
  UnitMeasure.create(Measurement.Length, Unit.Yard, "yd", 1.0936132983),
  UnitMeasure.create(Measurement.Length, Unit.Foot, "ft", 3.280839895),
  UnitMeasure.create(Measurement.Length, Unit.Inch, "in", 39.3700787),
  UnitMeasure.create(Measurement.Length, Unit.Mile, "mi", 0.0006213712),
  //area
  UnitMeasure.create(Measurement.Area, Unit.SquareMeter, "m^2"),
  UnitMeasure.create(
    Measurement.Area,
    Unit.SquareFoot,
    "ft^2",
    Math.pow(3.280839895, 2)
  ),
  UnitMeasure.create(
    Measurement.Area,
    Unit.SquareMeter,
    "km^2",
    Math.pow(0.001, 2)
  ),
  UnitMeasure.create(
    Measurement.Area,
    Unit.SquareMeter,
    "mi^2",
    Math.pow(0.0006213712, 2)
  ),
  //slope
  UnitMeasure.create(Measurement.Slope, Unit.RisePerRun, "y/x", 0.001),
  UnitMeasure.create(Measurement.Slope, Unit.SlopePercent, "%", 0.1),
  UnitMeasure.create(Measurement.Slope, Unit.MillimeterPerMeter, "mm/m"),
  UnitMeasure.create(Measurement.Slope, Unit.MeterPerKilometer, "m/km"),
  UnitMeasure.create(
    Measurement.Slope,
    Unit.InchPerMile,
    "in/mi",
    39.3700787 / 1000.0 / 0.0006213712
  ),
  //temperature
  UnitMeasure.create(Measurement.Temperature, Unit.Celsius, "°C"),
  UnitMeasure.create(
    Measurement.Temperature,
    Unit.Fahrenheit,
    "°F",
    9.0 / 5.0,
    32
  ),
  UnitMeasure.create(Measurement.Temperature, Unit.Kelvin, "K", 1, -273.15),
  //speed
  UnitMeasure.create(Measurement.Speed, Unit.MetersPerSecond, "m/s"),
  UnitMeasure.create(Measurement.Speed, Unit.KilometersPerHour, "km/h", 3.6),
  UnitMeasure.create(
    Measurement.Speed,
    Unit.MilesPerHour,
    "mph",
    3.6 / (1 / 0.6213712)
  ),
  //angle
  UnitMeasure.create(Measurement.Angle, Unit.AngleDegree, "°"),
  UnitMeasure.create(Measurement.Angle, Unit.Radian, "rad", Math.PI / 180.0),
  UnitMeasure.create(Measurement.Angle, Unit.AnglePercent, "%", 0),
  //acceleration
  UnitMeasure.create(
    Measurement.Acceleration,
    Unit.MetersPerSecondsSquared,
    "m/s^2"
  ),
  UnitMeasure.create(
    Measurement.Acceleration,
    Unit.FeetPerSecondsSquared,
    "ft/s^2",
    1 / 0.3048
  ),
  UnitMeasure.create(Measurement.Acceleration, Unit.Gal, "Gal", 1 / 0.01),
  UnitMeasure.create(
    Measurement.Acceleration,
    Unit.GravitationalAcceleration,
    "g0",
    1 / 9.80665
  ),
  //electric tension
  UnitMeasure.create(Measurement.ElectricTension, Unit.Volt, "V"),
  UnitMeasure.create(Measurement.ElectricTension, Unit.Millivolt, "mV", 1000),
];

const getSymbol = (unit: string) => {
  const unitMeasure = getUnitByName(unit);

  if (unitMeasure) {
    return unitMeasure.getSymbol();
  }

  return "";
};

const convertValue = (valueInBaseUnit: number, unit: string) => {
  const unitMeasure = getUnitByName(unit);

  if (unitMeasure) {
    return unitMeasure.fromBaseUnit(valueInBaseUnit);
  }

  return valueInBaseUnit;
};

const convertValueToBaseUnit = (valueInActualUnit: number, unit: string) => {
  const unitMeasure = getUnitByName(unit);

  if (unitMeasure) {
    return unitMeasure.toBaseUnit(valueInActualUnit);
  }

  return valueInActualUnit;
};

const convertUnit = (
  fromValue: number,
  fromUnit: Unit,
  toUnit: Unit
): number => {
  const fromUnitMeasure = getUnit(fromUnit);
  const toUnitMeasure = getUnit(toUnit);

  if (fromUnitMeasure && toUnitMeasure) {
    const fromBaseUnitValue = fromUnitMeasure.toBaseUnit(fromValue);
    const toUnitValue = toUnitMeasure.fromBaseUnit(fromBaseUnitValue);
    return toUnitValue;
  }

  return fromValue;
};

const metersToMiles = (meters: number) => {
  return convertUnit(meters, Unit.Meter, Unit.Mile);
};

const metersToFeet = (meters: number) => {
  return convertUnit(meters, Unit.Meter, Unit.Foot);
};

const metersSquareToFeetSquare = (meters: number) => {
  return convertUnit(meters, Unit.SquareMeter, Unit.SquareFoot);
};

const milesToMeters = (miles: number) => {
  return convertUnit(miles, Unit.Mile, Unit.Meter);
};

const getUnit = (unit: Unit): UnitMeasure | undefined => {
  return unitMeasures.find((um) => um.unit === unit);
};

const getUnitByName = (unitName: string): UnitMeasure | undefined => {
  return unitMeasures.find((um) => um.unit.toString() === unitName);
};

const unitConverter = {
  getUnit,
  getSymbol,
  convertValue,
  convertValueToBaseUnit,
  metersToMiles,
  metersToFeet,
  milesToMeters,
  metersSquareToFeetSquare,
};

export default unitConverter;
