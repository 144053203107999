import ThematicMapLayerRange from "../../../models/thematicMapLayerRange";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ColorPicker from "../../common/ColorPicker";
import nameOf from "../../../util/nameOfHelper";
import TableColum from "../../../models/tableColumn";
import { useEffect, useState } from "react";
import tableService from "../../../services/tableService";
import ProjectInfo from "../../../models/projectInfo";
import DropdownOption from "../../common/DropdownOption";
import Dropdown from "../../common/Dropdown";
import colorHelper from "../../../util/colorHelper";

const ThematicValuesEditor: React.FC<{
  project: ProjectInfo;
  tableColumn: TableColum;
  thematicMapLayerRanges: ThematicMapLayerRange[];
  onThematicMapLayerRangesChanged: any;
}> = ({
  project,
  tableColumn,
  thematicMapLayerRanges,
  onThematicMapLayerRangesChanged,
}) => {
  const [tableColumnValues, setTableColumnValues] = useState<string[]>([]);

  const [valueOptions, setValueOptions] = useState<DropdownOption<string>[]>(
    []
  );

  const [generateValuesWarning, setGenerateValuesWarning] = useState<string>();

  // eslint-disable-next-line
  const [rerenderCount, setRerenderCount] = useState<number>(1);

  useEffect(() => {
    const init = async () => {
      const { data: columnValues } = await tableService.getTableColumnValues(
        project.idProject,
        tableColumn.columnName
      );

      setTableColumnValues(columnValues);

      const options: DropdownOption<string>[] = columnValues.map((v) => {
        return { value: v, label: v };
      });

      setValueOptions(options);
    };

    setGenerateValuesWarning(undefined);

    init();
  }, [project, tableColumn]);

  const valueToDropdownOption = (value: string) => {
    var dropdownOption = new DropdownOption<string>();
    dropdownOption.label = value;
    dropdownOption.value = value;
    return dropdownOption;
  };

  useEffect(() => {
    for (const thematicMapLayerRange of thematicMapLayerRanges) {
      thematicMapLayerRange.selectedValueOption = valueToDropdownOption(
        thematicMapLayerRange.rangeValue
      );
    }

    // force re-render
    setRerenderCount((prev) => prev + 1);
  }, [thematicMapLayerRanges]);

  const onLabelChanged = (event: any, range: ThematicMapLayerRange) => {
    const newValue = event.target.value;
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeLabel)]: newValue,
    });
  };

  // const onRangeValueChanged = (event: any, range: ThematicMapLayerRange) => {
  //   const newValue = event.target.value;
  //   setNewRanges(range, {
  //     [nameOf<ThematicMapLayerRange>((p) => p.rangeValue)]: newValue,
  //   });
  // };

  const onValueOptionChaged = async (
    newValue: DropdownOption<string>,
    range: ThematicMapLayerRange
  ) => {
    range.selectedValueOption = newValue;

    if (!newValue.value) {
      return;
    }

    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeValue)]: newValue.value,
      [nameOf<ThematicMapLayerRange>((p) => p.rangeLabel)]: newValue.value,
    });
  };

  const onRangeColorChanged = (color: string, range: ThematicMapLayerRange) => {
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeColor)]: color,
    });
  };

  const onRangeShowColorPickerChanged = (range: ThematicMapLayerRange) => {
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.showColorPicker)]:
        !range.showColorPicker,
    });
  };

  const onRangeColorPickerClickOutside = (range: ThematicMapLayerRange) => {
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.showColorPicker)]: false,
    });
  };

  const setNewRanges = (range: ThematicMapLayerRange, props: any) => {
    const newRanges = thematicMapLayerRanges.map((r) =>
      r === range ? { ...r, ...props } : { ...r }
    );

    onThematicMapLayerRangesChanged(newRanges);
  };

  const onNewValue = () => {
    const newRange = new ThematicMapLayerRange();
    newRange.rangeColor = colorHelper.getRandomColor();

    const indexes: number[] = thematicMapLayerRanges.map((r) => r.index);
    newRange.index = indexes.length > 0 ? Math.max(...indexes) + 1 : 1;

    const newRanges = [...thematicMapLayerRanges];
    newRanges.push(newRange);

    onThematicMapLayerRangesChanged(newRanges);
  };

  const onDeleteValue = (range: ThematicMapLayerRange) => {
    const newRanges = thematicMapLayerRanges.filter((r) => r !== range);
    onThematicMapLayerRangesChanged(newRanges);
  };

  const onGenerateValues = () => {
    const newRanges: ThematicMapLayerRange[] = [];

    setGenerateValuesWarning(undefined);

    const maxItemsCount = 10;

    if (tableColumnValues.length > maxItemsCount) {
      setGenerateValuesWarning(
        `Too many values, generating ${maxItemsCount} values`
      );
    }

    let index = 1;
    for (const value of tableColumnValues.slice(0, maxItemsCount)) {
      const newRange = new ThematicMapLayerRange();
      newRange.rangeColor = colorHelper.getRandomColor();
      newRange.rangeLabel = value;
      newRange.rangeValue = value;
      newRange.selectedValueOption = valueToDropdownOption(value);
      newRange.index = index;
      index++;

      newRanges.push(newRange);
    }

    onThematicMapLayerRangesChanged(newRanges);
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <div>Values</div>
        <div className="image-button-link ms-1" onClick={onNewValue}>
          <AddIcon />
        </div>
        <div>
          <button
            className="btn btn-primary btn-sm ms-5"
            type="button"
            onClick={onGenerateValues}
          >
            Generate Values
          </button>
        </div>
        <div
          className="ms-2"
          style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
        >
          {generateValuesWarning}
        </div>
      </div>
      {thematicMapLayerRanges.length > 0 && (
        <table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Label</th>
              <th scope="col">Value</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {thematicMapLayerRanges.map(
              (value: ThematicMapLayerRange, index) => (
                <tr key={index.toString()}>
                  <td>
                    <div style={{ marginTop: "4px", marginRight: "4px" }}>
                      <div
                        style={{
                          padding: "5px",
                          background: "#fff",
                          borderRadius: "1px",
                          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                          display: "inline-block",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            background: value.rangeColor,
                          }}
                          onClick={() => onRangeShowColorPickerChanged(value)}
                        ></div>
                      </div>
                      {value.showColorPicker && (
                        <ColorPicker
                          color={value.rangeColor}
                          onColorChange={(color: any) =>
                            onRangeColorChanged(color.hex, value)
                          }
                          onClickOutside={() =>
                            onRangeColorPickerClickOutside(value)
                          }
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={value.rangeLabel}
                      onChange={(event) => onLabelChanged(event, value)}
                    />
                  </td>
                  <td>
                    {/* <input
                      type="text"
                      className="form-control"
                      style={{ width: "200px" }}
                      value={value.rangeValue ?? ""}
                      onChange={(event) => onRangeValueChanged(event, value)}
                    /> */}
                    <Dropdown
                      minWidth="200px"
                      height="35px"
                      options={valueOptions}
                      value={value.selectedValueOption}
                      onChange={(newValue: any) =>
                        onValueOptionChaged(newValue, value)
                      }
                    />
                  </td>
                  <td>
                    <div
                      className="image-button-link"
                      onClick={() => onDeleteValue(value)}
                    >
                      <ClearIcon />
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ThematicValuesEditor;
