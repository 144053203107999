import MapLayer from "../models/mapLayer";
import MapLayerVisibility from "../models/mapLayerVisibility";
import TableColum from "../models/tableColumn";
import ThematicMapLayerRange from "../models/thematicMapLayerRange";
import unitConverter from "../util/units/unitConverter";
import httpService from "./httpService";

const apiEndpoint = "/mapLayers";

const getMapLayers = async (
  idProject: number,
  tableColumns: TableColum[]
): Promise<MapLayer[]> => {
  const { data: mapLayers } = await httpService.get<MapLayer[]>(
    `${apiEndpoint}/getMapLayers?idProject=${idProject}`
  );

  // fix units

  for (const mapLayer of mapLayers) {
    if (!mapLayer.thematicMapLayer) {
      continue;
    }

    const fieldName = mapLayer.thematicMapLayer.fieldName;

    const tableColumn = tableColumns.find((c) => c.columnName === fieldName);

    if (!tableColumn) {
      continue;
    }

    fixRangesDisplayValues(
      mapLayer.thematicMapLayer.thematicMapLayerRanges,
      tableColumn
    );

    if (mapLayer.linkedMapLayer && mapLayer.linkedMapLayer.thematicMapLayer) {
      const linkedFieldName =
        mapLayer.linkedMapLayer.thematicMapLayer.fieldName;

      const linkedTableColumn = tableColumns.find(
        (c) => c.columnName === linkedFieldName
      );

      if (!linkedTableColumn) {
        continue;
      }

      fixRangesDisplayValues(
        mapLayer.linkedMapLayer.thematicMapLayer.thematicMapLayerRanges,
        linkedTableColumn
      );
    }
  }

  return Promise.resolve(mapLayers);
};

const fixRangesDisplayValues = (
  thematicMapLayerRanges: ThematicMapLayerRange[],
  tableColumn: TableColum
) => {
  for (const range of thematicMapLayerRanges) {
    if (range.hasRangeStart) {
      range.rangeStartDisplayValue = unitConverter.convertValue(
        range.rangeStartValue,
        tableColumn.unitMeasure
      );
    }

    if (range.hasRangeEnd) {
      range.rangeEndDisplayValue = unitConverter.convertValue(
        range.rangeEndValue,
        tableColumn.unitMeasure
      );
    }
  }
};

const createMapLayer = (mapLayer: MapLayer) => {
  return httpService.post(`${apiEndpoint}/createMapLayer`, mapLayer);
};

const updateMapLayer = (mapLayer: MapLayer) => {
  return httpService.post(`${apiEndpoint}/updateMapLayer`, mapLayer);
};

const deleteMapLayer = (idMapLayer: number) => {
  var data = new FormData();
  data.append("idMapLayer", idMapLayer.toString());
  return httpService.post(`${apiEndpoint}/deleteMapLayer`, data);
};

const updateMapLayerVisibilities = (
  mapLayerVisibilities: MapLayerVisibility[]
) => {
  return httpService.post(
    `${apiEndpoint}/updateMapLayerVisibilities`,
    mapLayerVisibilities
  );
};

const mapLayerService = {
  getMapLayers,
  createMapLayer,
  updateMapLayer,
  deleteMapLayer,
  updateMapLayerVisibilities,
};

export default mapLayerService;
