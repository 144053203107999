import ThematicMapLayerRange from "../../../models/thematicMapLayerRange";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ColorPicker from "../../common/ColorPicker";
import nameOf from "../../../util/nameOfHelper";
import TableColum from "../../../models/tableColumn";
import unitConverter from "../../../util/units/unitConverter";
import colorHelper from "../../../util/colorHelper";

const ThematicRangesEditor: React.FC<{
  tableColumn: TableColum;
  thematicMapLayerRanges: ThematicMapLayerRange[];
  onThematicMapLayerRangesChanged: any;
}> = ({
  tableColumn,
  thematicMapLayerRanges,
  onThematicMapLayerRangesChanged,
}) => {
  const onLabelChanged = (event: any, range: ThematicMapLayerRange) => {
    const newValue = event.target.value;
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeLabel)]: newValue,
    });
  };

  const onHasRangeStartChanged = (event: any, range: ThematicMapLayerRange) => {
    const checked = event.target.checked;
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.hasRangeStart)]: checked,
      [nameOf<ThematicMapLayerRange>((p) => p.rangeStartValue)]: 0,
    });
  };

  const onHasRangeEndChanged = (event: any, range: ThematicMapLayerRange) => {
    const checked = event.target.checked;
    setNewRanges(range, {
      hasRangeEnd: checked,
      rangeEndValue: 0,
    });
  };

  const onRangeStartValueChanged = (
    event: any,
    range: ThematicMapLayerRange
  ) => {
    const newValue = Number(event.target.value);
    let newValueSi = unitConverter.convertValueToBaseUnit(
      newValue,
      tableColumn.unitMeasure
    );

    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeStartDisplayValue)]:
        newValue,
      [nameOf<ThematicMapLayerRange>((p) => p.rangeStartValue)]: newValueSi,
    });
  };

  const onRangeEndValueChanged = (event: any, range: ThematicMapLayerRange) => {
    const newValue = Number(event.target.value);
    const newValueSi = unitConverter.convertValueToBaseUnit(
      newValue,
      tableColumn.unitMeasure
    );

    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeEndDisplayValue)]: newValue,
      [nameOf<ThematicMapLayerRange>((p) => p.rangeEndValue)]: newValueSi,
    });
  };

  const onRangeColorChanged = (color: string, range: ThematicMapLayerRange) => {
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeColor)]: color,
    });
  };

  const onRangeShowColorPickerChanged = (range: ThematicMapLayerRange) => {
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.showColorPicker)]:
        !range.showColorPicker,
    });
  };

  const onRangeColorPickerClickOutside = (range: ThematicMapLayerRange) => {
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.showColorPicker)]: false,
    });
  };

  const setNewRanges = (range: ThematicMapLayerRange, props: any) => {
    const newRanges = thematicMapLayerRanges.map((r) =>
      r === range ? { ...r, ...props } : { ...r }
    );

    onThematicMapLayerRangesChanged(newRanges);
  };

  const onNewRange = () => {
    const newRange = new ThematicMapLayerRange();
    newRange.rangeColor = colorHelper.getRandomColor();
    newRange.hasRangeStart = true;
    newRange.hasRangeEnd = true;

    const indexes: number[] = thematicMapLayerRanges.map((r) => r.index);
    newRange.index = indexes.length > 0 ? Math.max(...indexes) + 1 : 1;

    const newRanges = [...thematicMapLayerRanges];
    newRanges.push(newRange);

    onThematicMapLayerRangesChanged(newRanges);
  };

  const onDeleteRange = (range: ThematicMapLayerRange) => {
    const newRanges = thematicMapLayerRanges.filter((r) => r !== range);
    onThematicMapLayerRangesChanged(newRanges);
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <div>Ranges</div>
        <div className="image-button-link ms-1" onClick={onNewRange}>
          <AddIcon />
        </div>
      </div>
      {thematicMapLayerRanges.length > 0 && (
        <table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Label</th>
              <th scope="col"></th>
              <th scope="col">Range Start</th>
              <th scope="col"></th>
              <th scope="col">&lt;= Range End</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {thematicMapLayerRanges.map(
              (range: ThematicMapLayerRange, index) => (
                <tr key={index.toString()}>
                  <td>
                    <div style={{ marginTop: "4px", marginRight: "4px" }}>
                      <div
                        style={{
                          padding: "5px",
                          background: "#fff",
                          borderRadius: "1px",
                          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                          display: "inline-block",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            background: range.rangeColor,
                          }}
                          onClick={() => onRangeShowColorPickerChanged(range)}
                        ></div>
                      </div>
                      {range.showColorPicker && (
                        <ColorPicker
                          color={range.rangeColor}
                          onColorChange={(color: any) =>
                            onRangeColorChanged(color.hex, range)
                          }
                          onClickOutside={() =>
                            onRangeColorPickerClickOutside(range)
                          }
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={range.rangeLabel}
                      onChange={(event) => onLabelChanged(event, range)}
                    />
                  </td>
                  <td align="right">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={range.hasRangeStart}
                      onChange={(event) => onHasRangeStartChanged(event, range)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      style={{ width: "100px" }}
                      // value={
                      //   range.hasRangeStart
                      //     ? unitConverter.convertValue(
                      //         range.rangeStartValue,
                      //         tableColumn.unitMeasure
                      //       )
                      //     : ""
                      // }
                      value={
                        range.hasRangeStart ? range.rangeStartDisplayValue : ""
                      }
                      onChange={(event) =>
                        onRangeStartValueChanged(event, range)
                      }
                      step="0.001"
                      disabled={!range.hasRangeStart}
                    />
                  </td>
                  <td align="right">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={range.hasRangeEnd}
                      onChange={(event) => onHasRangeEndChanged(event, range)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      style={{ width: "100px" }}
                      // value={
                      //   range.hasRangeEnd
                      //     ? unitConverter.convertValue(
                      //         range.rangeEndValue,
                      //         tableColumn.unitMeasure
                      //       )
                      //     : ""
                      // }
                      value={
                        range.hasRangeEnd ? range.rangeEndDisplayValue : ""
                      }
                      onChange={(event) => onRangeEndValueChanged(event, range)}
                      step="0.001"
                      disabled={!range.hasRangeEnd}
                    />
                  </td>
                  <td>
                    <div
                      className="image-button-link"
                      onClick={() => onDeleteRange(range)}
                    >
                      <ClearIcon />
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ThematicRangesEditor;
